<template>
  <div :class="$style.deviceBox">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">设备管理</span>
      </template>
      <div :class="$style.manageContent" ref="content" slot="content">
        <div :class="$style.form">
          <a-form-model ref="ruleForm" layout="inline">
            <!-- <a-form-model-item>
              <a-input-search
                v-model="query"
                placeholder="设备编号"
                style="width: 200px"
                @change="input => changeQuery(input)"
                @search="handleSearch"
              />
            </a-form-model-item> -->
            <!-- <a-form-model-item>
              <a-input-search
                v-model="currentBind"
                placeholder="当前绑定"
                style="width: 200px"
                @search="handleCurrentBindChange"
              />
            </a-form-model-item> -->
            <a-form-model-item>
              <a-select
                style="width: 180px"
                v-model="searchForm.searchProductClassfy"
                allow-clear
                placeholder="全部产品分类"
                @change="handleProductSortChange"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="item in productClassfy"
                  :key="item.id"
                  :value="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-input-search
                v-model="query"
                placeholder="设备编号/门店/当前绑定/备注"
                style="width: 300px"
                @change="input => changeQuery(input)"
                @search="handleSearch"
              />
            </a-form-model-item>
            <!-- <a-form-model-item>
              <a-select
                style="width: 180px"
                v-model="searchForm.softwareVersion"
                placeholder="全部软件版本"
                allow-clear
                @change="handleSoftwareVersionChange"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option
                  v-for="item in softwareVersionList"
                  :key="item.id"
                  :value="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <!-- <a-form-model-item>
              <a-select
                style="width: 200px"
                v-model="searchForm.equipmentLayer"
                placeholder="全部设备层数"
                allow-clear
                @change="handleDeviceLayerChange"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="item in 5" :key="item" :value="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <!-- <a-form-model-item>
              <a-select
                style="width: 200px"
                v-model="searchForm.deviceStatus"
                placeholder="全部设备状态"
                allow-clear
                @change="handleDeviceStatusChange"
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="BIND">已绑定</a-select-option>
                <a-select-option value="UNBIND">待绑定</a-select-option>
              </a-select>
            </a-form-model-item> -->
          </a-form-model>
          <div>
            <a-button
              @click="handleBatchUpdate"
              style="margin-right: 10px"
              v-if="$p.action('BATCH_UPDATE')"
            >
              批量更新
            </a-button>
            <a-button @click="handleBatchDownload" style="margin-right: 10px">
              批量下载
            </a-button>
            <a-button type="primary" @click="handleBindDevice">
              绑定设备
            </a-button>
          </div>
        </div>
        <a-table
          :columns="columns"
          :dataSource="tableList"
          :pagination="pagination"
          :scroll="{ x: 1600 }"
          :class="$style.table"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          @change="handlePagination"
        >
          <template slot="equipmentId" slot-scope="text, record">
            <span>{{
              record.equipmentName ? record.equipmentName : text
            }}</span>
            <x-icon
              :class="$style.iconQrcode"
              @click.stop="handleQrcode(record)"
              type="tc-icon-qrcode"
            ></x-icon>
          </template>
          <template slot="equipmentLayer" slot-scope="text, record">
            <span
              style="cursor: pointer"
              @click.stop="handleShowLayer(record)"
              :class="$style.layerColor"
              >{{ text }}</span
            >
          </template>
          <span slot="bindMember">
            <span slot="bindMember">当前绑定</span>
            <a-tooltip placement="top">
              <template slot="title">
                <span>
                  添加设备时被绑定的用户，需要配置烤箱管理模块后方可使用软件！
                </span>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
          <template slot="equipmentStatus" slot-scope="text">
            <span v-if="text === 'BIND'" :class="$style.bind"> 已绑定 </span>
            <span v-else :class="$style.unbind"> 待绑定 </span>
          </template>
          <template slot="bindMember" slot-scope="text">
            <div
              v-if="text !== null"
              style="display: flex; align-items: center"
              @click.stop=""
            >
              <Avatar :name="text.memberName" :img="text.avatar" />
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{ text.mobilePhone }}</span>
                  <span
                    @click.stop="handleCopy(text)"
                    style="
                      color: var(--primary);
                      margin-left: 10px;
                      cursor: pointer;
                    "
                  >
                    点击复制
                  </span>
                </template>
                <x-icon
                  :class="$style.iconStyle"
                  type="tc-icon-dianhua1"
                ></x-icon>
              </a-tooltip>
            </div>
          </template>
          <template slot="engineerMemberList" slot-scope="text">
            <div
              v-if="text && text.length > 0"
              style="display: flex; align-items: center"
              @click.stop=""
            >
              <Avatar :name="text[0].memberName" :img="text[0].avatar" />
              <a-tooltip placement="top">
                <template slot="title">
                  <span>{{ text[0].mobilePhone }}</span>
                  <span
                    @click.stop="handleCopy(text[0])"
                    style="
                      color: var(--primary);
                      margin-left: 10px;
                      cursor: pointer;
                    "
                  >
                    点击复制
                  </span>
                </template>
                <x-icon
                  :class="$style.iconStyle"
                  type="tc-icon-dianhua1"
                ></x-icon>
              </a-tooltip>
            </div>
          </template>
          <template slot="shopList" slot-scope="text">
            <a-tooltip>
              <template slot="title">
                <div v-for="(item, index) in text" :key="index">
                  {{ item }}
                </div>
              </template>
              <span>
                {{ text[0] }}
              </span>
            </a-tooltip>
          </template>
          <template slot="action" slot-scope="record">
            <span
              v-if="record.equipmentStatus === 'BIND'"
              :class="$style.statusActived"
              @click.stop="openDetail(record)"
            >
              详情
            </span>
            <span
              :class="$style.statusActived"
              @click.stop="handleRemark(record)"
            >
              备注
            </span>
            <span
              v-if="record.equipmentStatus !== 'BIND'"
              :class="$style.statusActived"
              @click.stop="handleBinding"
            >
              绑定
            </span>
            <span
              v-if="record.equipmentStatus === 'BIND'"
              :class="$style.statusDisabled"
              @click.stop="handleUnbind(record)"
            >
              解绑
            </span>
          </template>
        </a-table>
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import dayjs from 'dayjs';
import {
  createModal,
  createFormModal,
  createDrawer,
} from '@triascloud/x-components';
import ShowQrcode from './components/show-qrcode.vue';
import Detail from './components/detail';
import ShowLayer from './components/show-layer.vue';
import Tip from '@/components/tip/index';
import BatchUpdate from './components/batch-update.vue';
import Avatar from '@/components/avatar/avatar.vue';
import { clipboardWrite } from '@triascloud/utils';
import {
  getDeviceList,
  getProcuctClassfy,
  // getPDeviceLayers,
  getSoftwareVersionList,
  unbindDevice,
  batchDownload,
  putRemark,
} from '@/services/oven/device';
import QRCode from 'qrcode';
import XDownloadAnchor from '@/components/common/x-download-anchor/index.vue';
import { Debounce } from 'lodash-decorators';
import { crossStorageModule } from '@/enum/store';
import { AInput } from '@triascloud/x-blocks/dist/ant-design-vue';

@Component({
  components: {
    LayoutContent,
    Avatar,
  },
})
export default class Device extends Vue {
  @crossStorageModule.State('tenant') tenant;
  query = '';
  handleSearch(v) {
    this.query = v;
    this.getList();
  }
  @Debounce(300)
  changeQuery(e) {
    this.query = e.target.value;
    this.getList();
  }
  searchForm = {
    equipmentLayer: undefined,
    deviceStatus: undefined,
    softwareVersion: undefined,
    searchProductClassfy: undefined,
    keyword: '',
  };
  handleDeviceLayerChange(v) {
    this.searchForm.equipmentLayer = v;
    this.getList();
  }
  handleDeviceStatusChange(v) {
    this.searchForm.deviceStatus = v;
    this.getList();
  }
  handleSoftwareVersionChange(v) {
    this.searchForm.softwareVersion = v;
    this.getList();
  }
  handleProductSortChange(v) {
    this.searchForm.searchProductClassfy = v;
    this.getList();
  }
  currentBind = '';
  handleCurrentBindChange(v) {
    this.currentBind = v;
    this.getList();
  }

  mounted() {
    this.getList();
    this.getProcuctClassfyList();
    // this.getLayers();
    this.softwareVersionData();
  }
  productClassfy = [];
  async getProcuctClassfyList() {
    this.productClassfy = [];
    try {
      const result = await getProcuctClassfy();
      result.forEach((v, index) => {
        const o = {};
        o.id = index;
        o.label = v;
        this.productClassfy.push(o);
      });
    } catch {
      return false;
    }
  }
  // 层数
  // layerList = [];
  // async getLayers() {
  //   this.layerList = [];
  //   try {
  //     const data = await getPDeviceLayers();
  //     data.forEach((v, index) => {
  //       const o = {};
  //       o.id = index;
  //       o.label = v;
  //       this.layerList.push(o);
  //     });
  //   } catch {
  //     return false;
  //   }
  // }
  softwareVersionList = [];
  async softwareVersionData() {
    try {
      const data = await getSoftwareVersionList();
      data.forEach((v, index) => {
        const o = {};
        o.id = index;
        o.label = v;
        this.softwareVersionList.push(o);
      });
      const version = this.$route.query.productVersion;
      if (version) {
        this.searchForm.softwareVersion = version;
        await this.getList();
      }
    } catch {
      return false;
    }
  }
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: '序号',
        align: 'center',
        fixed: 'left',
        width: 80,
      },
      {
        align: 'left',
        title: '设备编号/备注',
        dataIndex: 'equipmentId',
        width: 240,
        scopedSlots: { customRender: 'equipmentId' },
      },
      {
        align: 'left',
        title: '设备层数',
        dataIndex: 'equipmentLayer',
        scopedSlots: { customRender: 'equipmentLayer' },
      },
      {
        align: 'left',
        title: '设备状态',
        dataIndex: 'equipmentStatus',
        scopedSlots: { customRender: 'equipmentStatus' },
      },
      {
        align: 'left',
        dataIndex: 'bindMember',
        slots: { title: 'bindMember' },
        scopedSlots: { customRender: 'bindMember' },
      },
      {
        align: 'left',
        title: '负责工程师',
        dataIndex: 'engineerMemberList',
        scopedSlots: { customRender: 'engineerMemberList' },
      },
      {
        align: 'left',
        title: '所属门店',
        dataIndex: 'shopList',
        scopedSlots: { customRender: 'shopList' },
      },
      {
        align: 'left',
        title: '最近绑定时间',
        dataIndex: 'bindDate',
        customRender: val => (val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''),
      },
      {
        align: 'left',
        title: '操作',
        fixed: 'right',
        width: 200,
        scopedSlots: { customRender: 'action' },
      },
    ];
  }
  tableList = [];
  async getList() {
    const params = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
      keyword: this.query !== '' ? this.query : undefined,
      bindKeyword: this.currentBind !== '' ? this.currentBind : undefined,
      productType:
        this.searchForm.searchProductClassfy !== undefined
          ? this.searchForm.searchProductClassfy
          : undefined,
      equipmentLayer:
        this.searchForm.equipmentLayer !== undefined
          ? this.searchForm.equipmentLayer
          : undefined,
      equipmentStatus:
        this.searchForm.deviceStatus !== undefined
          ? this.searchForm.deviceStatus
          : undefined,
      softwareVersion:
        this.searchForm.softwareVersion !== undefined
          ? this.searchForm.softwareVersion
          : undefined,
      isPc: true,
    };
    try {
      const { records, current, total } = await getDeviceList(params);
      this.tableList = records;
      this.pagination.current = current;
      this.pagination.total = total;
    } catch {
      return false;
    }
  }

  selectedRowKeys = [];
  selectedList = [];
  /** @name 选中设备的单元id列表 */
  unitIds = [];
  onSelectChange(selectedRowKeys, selectedRows) {
    this.selectedRowKeys = selectedRowKeys;
    let unitResult = [];
    selectedRows.forEach(row => {
      row.kolbUnitInEquipmentUnits &&
        row.kolbUnitInEquipmentUnits.forEach(unit => {
          unitResult.push(unit.pkId);
        });
    });
    this.unitIds = unitResult;
    this.selectedList = selectedRows.map(v => {
      return {
        pkId: v.pkId,
        equipmentId: v.equipmentId,
        equipmentKey: v.equipmentKey,
        equipmentStatus: v.equipmentStatus === 'BIND' ? '已绑定' : '待绑定',
      };
    });
  }

  openDetail(item) {
    createDrawer(() => <Detail equipmentId={item.pkId} />, {
      title: '设备详情',
      width: '890px',
      wrapClassName: 'device-box-tableList',
    });
  }
  model = '';
  async handleBatchUpdate() {
    if (this.selectedList.length === 0) {
      this.$message.warning('请至少选择一个设备');
      return;
    }
    const pkId = this.tenant.pkId;
    const idList = this.selectedList.map(v => v.pkId);
    this.model = await createFormModal(
      () => <BatchUpdate idList={idList} pkIds={pkId} unitIds={this.unitIds} />,
      {
        width: 470,
        title: '批量更新',
        onCancel: () => this.handleCloses(),
      },
    );
    this.selectedList = [];
    this.selectedRowKeys = [];
  }
  handleCloses() {
    this.selectedList = [];
    this.selectedRowKeys = [];
  }
  async getBase64(str) {
    return QRCode.toDataURL(str, {
      margin: 4,
      color: {
        light: '#ffffffFF',
        dark: '#000000FF',
      },
    });
  }
  async getQRCodeBase64(list = []) {
    if (list.length === 0) {
      return;
    }
    const result = [];
    for (let i = 0; i < list.length; i++) {
      const fileName = `${list[i].equipmentId}-${list[i].equipmentStatus}`;
      const codeStr = `${list[i].equipmentId}-${list[i].equipmentKey}`;
      const o = {
        base64Str: await this.getBase64(codeStr),
        fileName: `${fileName}.jpg`,
      };
      result.push(o);
    }
    return result;
  }
  async handleBatchDownload() {
    if (this.selectedList.length === 0) {
      this.$message.warning('请至少选择一个设备');
      return;
    }
    const list = this.selectedList;
    const length = list.length;
    const params = {
      imageList: await this.getQRCodeBase64(list),
    };
    if (length > 1) {
      params.zipName = `设备编号` + dayjs().format('YYYYMMDDHHmmss');
      try {
        await batchDownload(params);
        this.$message.success('批量下载成功');
      } catch {
        return false;
      }
    } else {
      XDownloadAnchor.download(
        params.imageList[0].base64Str,
        params.imageList[0].fileName,
      );
    }
    this.selectedRowKeys = [];
    this.selectedList = [];
  }
  handleBindDevice() {
    this.$message.info('暂不支持PC端绑定！');
  }
  handleBinding() {
    this.$message.info('暂不支持PC端绑定！');
  }
  async handleUnbind(row) {
    await createFormModal(
      () => (
        <Tip>
          <span slot="txt">确认解绑当前设备？</span>
          <span slot="subTxt">
            解绑后设备将重新进入”待绑定“状态，绑定的关联人员将会被全部移除！
          </span>
        </Tip>
      ),
      {
        width: '440px',
        title: '操作提示',
        onOk: () => {
          this.unbindDevices(row.pkId);
        },
      },
    );
  }

  async unbindDevices(rowId) {
    const formData = {
      equipmentPkId: rowId,
    };
    try {
      await unbindDevice(formData);
      await this.getList();
      this.$message.success('解绑成功');
    } catch {
      return false;
    }
  }
  handleShowLayer(row) {
    const pkId = row.pkId;
    const result = createModal(
      () => (
        <ShowLayer
          pkId={pkId}
          onClose={() => {
            result.handleClosed();
          }}
        />
      ),
      {
        width: 1044,
        title: '层数详情',
      },
    );
  }
  handleQrcode(item) {
    const deviceItem = {
      equipmentId: item.equipmentId,
      equipmentKey: item.equipmentKey,
      pkId: item.pkId,
    };
    createModal(() => <ShowQrcode deviceItem={deviceItem} />, {
      width: 300,
      title: '二维码',
    });
  }

  async handleCopy(item) {
    const str = `姓名：${item.bindMember.memberName}\n手机号码：${item.bindMember.mobilePhone}`;
    await clipboardWrite(str);
    this.$message.success('复制成功');
  }

  remark = '';
  async handleRemark(row) {
    this.remark = row.equipmentName;
    await createFormModal(
      () => (
        <AInput
          placeholder="设备备注，限18字符长度"
          maxLength={18}
          v-model={this.remark}
        />
      ),
      {
        width: '440px',
        title: '设备备注',
        onOk: async () => {
          const data = {
            equipmentName: this.remark,
            equipmentPkId: row.pkId,
          };
          await putRemark(data);
          await this.getList();
          await this.$message.success({
            content: '修改成功！',
            duration: 0.5,
          });
        },
      },
    );
  }
}
</script>
<style lang="less" module>
.title {
  font-size: 16px;
}
.deviceBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  :global {
    .ant-table-wrapper .ant-spin-container .ant-table-body,
    .ant-table-wrapper .ant-table-scroll .ant-table-body {
      flex: 1;
      overflow: auto !important;
      max-height: none !important;
    }
  }
  .statusDisabled {
    cursor: pointer;
    color: var(--delete);
  }
  .statusActived {
    cursor: pointer;
    margin-right: 10px;
    color: var(--primary);
  }

  .layerColor {
    color: var(--primary);
  }

  .iconQrcode {
    margin-left: 10px;
    cursor: pointer;
    color: var(--primary);
  }

  .manageContent {
    padding: 20px 28px;
    min-width: 850px;
  }
}
.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  :global(.ant-form) {
    display: flex;
    align-items: center;
  }
  .btn {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.bind {
  color: #41c400;
}
.unbind {
  color: #f90;
}
.iconStyle {
  cursor: pointer;
  color: var(--primary);
}
:global(.device-box-tableList .ant-drawer-body) {
  padding: 0;
  overflow-y: hidden;
}
</style>
